import { React, useState, useEffect, useRef } from "react";
import axios from 'axios';
import { Modal } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

const DatosPeticion = ({ propiedad, llegada, salida, difdias, adultos, ninos, bebes, ruta, numpropiedades, websgrupo, precios, moneda, lang }) => {

    let { t } = useTranslation();

    let vectornumpropiedades = []

    for (let i = 0; i < numpropiedades; i++) {
        if (i == 0)
            vectornumpropiedades = vectornumpropiedades.concat(<option value={i + 1} key={i}>{(i + 1) + " " + t("propiedad")} </option>)
        else
            vectornumpropiedades = vectornumpropiedades.concat(<option value={i + 1} key={i}>{(i + 1) + " " + t("propiedades")} </option>)
    }

    {/* *************** */ }

    const cantApartRef = useRef(1);

    const nombrecRef = useRef(null);
    const apellidoscRef = useRef(null);
    const emailcRef = useRef(null);
    const telefonocRef = useRef(null);
    const documentocRef = useRef(null);
    const aceptacondicionesRef = useRef(null);

    const formulariopeticionRef = useRef(null);

    const datospeticionactualesRef = useRef(null);

    const numpropiedades0Ref = useRef(null);

    const [idTarifaRef, setIdTarifaRef] = useState(null);
    const [priceRef, setPriceRef] = useState(null);
    const [prepagoRef, setPrepagoRef] = useState(null);

    const feesRef = useRef(0);
    const pextraRef = useRef(null);
    const preciosDiariosRef = useRef(null);
    const datosreservaactualesRef = useRef(null);

    let resprices = [];

    {/* *************** */ }
    const handleChangeTarifa = () => {

        console.log("Cambio tarifa");

        var numreservas = cantApartRef.current.value;

        var precioEstancia = Number(document.querySelector("input[type='radio'][name='tarifa1']:checked").value * numreservas);
        var precioExtras = propiedad.fees / 100;
        var precioTotal = Number(precioEstancia) + Number(precioExtras);
        var prepago = Number(document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute('data-prepago'));

        feesRef.current.value = precioExtras;

        var nuevoPrecioTotal = roundN(parseFloat(precioEstancia) + parseFloat(precioExtras), 2);

        var nuevoPrepago = (nuevoPrecioTotal * prepago) / 100;
        var nuevoResto = nuevoPrecioTotal - nuevoPrepago;

        document.querySelectorAll(".pricebase").forEach(function (elemento) {
            elemento.textContent = roundN(Number(precioEstancia), 2) + " " + moneda;
        });

        document.querySelectorAll(".totalprice").forEach(function (elemento) {
            elemento.textContent = roundN(Number(nuevoPrecioTotal), 2) + " " + moneda;
        });

        document.querySelectorAll(".prepago").forEach(function (elemento) {
            elemento.textContent = roundN(Number(nuevoPrepago), 2)
        });

        pextraRef.current.textContent = roundN(Number(precioExtras), 2);

        setIdTarifaRef(document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute("data-tarifa"));


        setPriceRef(btoa(precioEstancia));
        setPrepagoRef(prepago);

        preciosDiariosRef.current.value = document.querySelector("input[type='radio'][name='tarifa1']:checked").getAttribute('data-preciosdiarios');

        datosreservaactualesRef.current.setAttribute("data-precioestancia", (Number(precioEstancia), 2));
        datosreservaactualesRef.current.setAttribute("data-totalprice", roundN(Number(precioTotal), 2));

    }

    {/* **************** */ }


    const roundN = (value, decimals) => {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);
    }

    const handleChangeNumPropiedades = () => {
        cantApartRef.current.value = numpropiedades0Ref.current.value;
        handleChangeTarifa();
    };

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
        setShowModal(!showModal);
    }

    const handleSubmit = () => {
        if (nombrecRef.current.value && nombrecRef.current.value.replace(/\s+/g, '').length > 0 &&
            apellidoscRef.current.value && apellidoscRef.current.value.replace(/\s+/g, '').length > 0 &&
            emailcRef.current.value && emailcRef.current.value.replace(/\s+/g, '').length > 0 &&
            telefonocRef.current.value && telefonocRef.current.value.replace(/\s+/g, '').length > 0 &&
            documentocRef.current.value && documentocRef.current.value.replace(/\s+/g, '').length > 0 &&
            aceptacondicionesRef.current.checked)
            formulariopeticionRef.current.submit();
        else
            handleShowModal()
    }

    const handleBack = () => {
        window.history.back();
    }

    const [showMore, setshowMore] = useState(-1);

    {/* *************** */ }

    let precioBase = 0;
    let cuentaNoches = 0;

    precios.map((unprecio, index) => {

        let acumulado = unprecio.suma / 100;

        let preciosdiarios = unprecio.calendario_sin_otas
        for (let i = 0; i < preciosdiarios.length; i++) {
            {/*acumulado += preciosdiarios[i].precio / 100;*/ }
            if (index === 0) cuentaNoches++;
        }
        if (index === 0) {
            precioBase = acumulado.toFixed(2);
        }
    })

    useEffect(() => {
        handleChangeTarifa();
    }, []);

    {/* *************** */ }

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-7 col-lg-8">

                    {/*-- ********************** TARIFES  ********************************* */}
                    <div className="metro-box-wrapper">
                        <h3>{t("Tarifas")}</h3>
                        <div className="linia"></div>
                        <div className="content">
                            {precios.map((unprecio, index) => {

                                let acumulado = unprecio.suma / 100;
                                let checked = ""
                                let preciosdiarios = unprecio.calendario_sin_otas
                                for (let i = 0; i < preciosdiarios.length; i++) {
                                    if (index === 0) cuentaNoches++;
                                }
                                if (index === 0) {
                                    precioBase = acumulado.toFixed(2);
                                }

                                acumulado = acumulado.toFixed(2);
                                resprices = resprices.concat(
                                    <div className="tarifes" key={"t" + index}>
                                        <div className="content">
                                            <div className="mt-radio-list m15_1_1_1">
                                                <label className="mt-radio radiotarifa">
                                                    {index === 0 ?
                                                        <input type="radio" name="tarifa1" className="tarifa1" defaultChecked id="tarifa" defaultValue={acumulado} data-preciosdiarios="" data-prepago={unprecio.info_tarifa.politica.prepago} data-idpolitica={unprecio.info_tarifa.politica_id} data-tarifa={unprecio.id} data-texto={unprecio.info_tarifa.politica.descripcion[lang]} onClick={() => handleChangeTarifa()} />
                                                        :
                                                        <input type="radio" name="tarifa1" className="tarifa1" id="tarifa" defaultValue={acumulado} data-preciosdiarios="" data-prepago={unprecio.info_tarifa.politica.prepago} data-idpolitica={unprecio.info_tarifa.politica_id} data-tarifa={unprecio.id} data-texto={unprecio.info_tarifa.politica.descripcion[lang]} onClick={() => handleChangeTarifa()} />
                                                    }
                                                    <strong>{" " + acumulado + " " + moneda}  </strong> - {" " + unprecio.info_tarifa.nombre_web}
                                                </label>
                                                <p className="small ms-3" onClick={() => { showMore == index ? setshowMore(-1) : setshowMore(index) }}>
                                                    <span className="small bolder">{unprecio.info_tarifa.politica.nombre[lang]}</span> <span className="cursor-pointer small bolder fst-italic">({showMore == index ? t('Esconder') : t('Mostrar')} {t('Descripción')})</span>
                                                </p>
                                                {showMore === index && (
                                                    <>
                                                        <hr className="mt-0" />
                                                        <p className="small" dangerouslySetInnerHTML={{ __html: unprecio.info_tarifa.politica.descripcion[lang] }}></p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                            {resprices}
                        </div>
                    </div>

                    { /*-- ********************** FINAL TARIFES  ********************************* */}

                    <form method="get" action="/resumenpeticion" ref={formulariopeticionRef}>
                        <input type="hidden" name="idpropiedad" id="idPropiedad" defaultValue={propiedad.id} />
                        <input type="hidden" name="llegada" defaultValue={llegada} id="llegada" />
                        <input type="hidden" name="salida" defaultValue={salida} id="salida" />
                        <input type="hidden" name="adultos" defaultValue={adultos} />
                        <input type="hidden" name="ninos" defaultValue={ninos} />
                        <input type="hidden" name="bebes" defaultValue={bebes} />
                        <input type="hidden" name="idusuario" id="user" defaultValue="" />
                        <input type="hidden" name="difdias" defaultValue={difdias} />
                        <input type="hidden" name="price" id="price" defaultValue={priceRef} />
                        <input type="hidden" name="idTarifa" id="idTarifa" defaultValue={idTarifaRef} />
                        <input type="hidden" name="fees" id="fees" defaultValue={propiedad.fees / 100} ref={feesRef} />
                        <input type="hidden" name="prepago" id="prepago" defaultValue={prepagoRef} />
                        <input type="hidden" name="cantidadAparts" id="cantidadAparts" defaultValue="1" ref={cantApartRef} />
                        <input type="hidden" name="preciosDiarios" id="preciosDiarios" defaultValue="" ref={preciosDiariosRef} />

                        {/* ***************** DADES CLIENT ***************** */}
                        <div className="metro-box-wrapper" id="userInfo">
                            <h3>{t("Información")}</h3>
                            <div className="linia"></div>
                            <div className="content">
                                <div className="form-horizontal">
                                    <div className="form-group row">
                                        <label className="mt-1 col-md-6 col-lg-2">{t("Nombre")} *</label>
                                        <div className="mb-2 col-md-6 col-lg-4">
                                            <input type="text" className="mb-0 form-control" placeholder={t("Nombre")} name="firstname" required defaultValue="" id="nombrec" ref={nombrecRef} />
                                        </div>
                                        <label className="mt-1 col-md-6 col-lg-2">{t("Apellidos")} *</label>
                                        <div className="mb-2 col-md-6 col-lg-4">
                                            <input type="text" className="mb-0 form-control" placeholder={t("Apellidos")} name="lastname" required defaultValue="" id="apellidosc" ref={apellidoscRef} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="mt-1 col-md-6 col-lg-2">{t("Email")} *</label>
                                        <div className="mb-2 col-md-6 col-lg-4">
                                            <input id="emailc" type="email" className="mb-0 form-control" placeholder={t("Email")} name="email" required defaultValue="" ref={emailcRef} />
                                        </div>
                                        <label className="mt-1 col-md-6 col-lg-2">{t("Teléfono")} *</label>
                                        <div className="mb-2 col-md-6 col-lg-4">
                                            <input type="text" className="mb-0 form-control" placeholder={t("Teléfono")} name="phone" required defaultValue="" id="telefonoc" ref={telefonocRef} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="mt-1 col-md-6 col-lg-2">{t("Pasaporte/ID")}*</label>
                                        <div className="mb-2 col-md-6 col-lg-4">
                                            <input id="documentoc" type="text" className="mb-0 form-control" placeholder={t("Pasaporte/ID")} name="documentousuario" required defaultValue="" ref={documentocRef} />
                                        </div>
                                        <label className="mt-1 col-md-6 col-lg-2">{t("Ciudad")}</label>
                                        <div className="mb-2 col-md-6 col-lg-4">
                                            <input type="text" className="mb-0 form-control" placeholder={t("Ciudad")} name="city" defaultValue="" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="mt-5 col-md-12 text-end">{t("Todos los campos * son obligatorios")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ***************** FINAL DADES CLIENT ***************** */}

                    </form>

                    <Modal
                        show={showModal} onHide={() => setShowModal(false)}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        size="lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Error")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="clearfix mb-20 ml-20 row">
                                {t("Campos * son obligatorios")}
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>


                <div className="col-md-5 col-lg-4">
                    <div className="mt-5 caixa-preus sticky-top">
                        <div className="row col-md-12">
                            <div className="col-md-12 col-lg-5">
                                {
                                    propiedad.foto_principal.length != 0
                                        ? <img src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + propiedad.foto_principal[0].nombre} alt="--" title="" className="imatge-reserva-pas2" />
                                        : <img src="/images/web/bedloop-nofoto.png" alt="--" title="" className="imatge-reserva-pas2" />
                                }
                            </div>
                            <div className="col-md-12 col-lg-7">
                                <h5>{propiedad.nombre}</h5>
                                <p className="small"><i className="fa-duotone fa-location-dot"></i> {propiedad.ciudad + ", " + propiedad.region}</p>
                            </div>
                        </div>
                        <div className="mt-3 mb-5 text-start">
                            <button className="btn btn-primary" onClick={handleBack} style={{ background: "" + websgrupo.color_corporativo_web + "", border: "1px solid" + websgrupo.color_corporativo_web }}>
                                {t("Cambiar fechas")}
                            </button>
                        </div>
                        {websgrupo.activa_multiunit == 1 && numpropiedades > 1
                            ?
                            <>
                                <h4>{t("Número de propiedades")}</h4>
                                <div className="linia"></div>
                                <div className="mt-3 mb-3">
                                    <select className="mb-0 form-control extra" id="numpropiedades0" onChange={() => handleChangeNumPropiedades()} defaultValue="1" ref={numpropiedades0Ref}>
                                        {vectornumpropiedades}
                                    </select>
                                </div>

                            </>
                            :
                            null
                        }


                        <h4>{t("Resumen")}</h4>
                        <div className="linia"></div>

                        <div className="mt-3 mb-3 row">
                            <div className="col-6">{t("Entrada")} {llegada}</div>
                            <div className="col-6 text-end">{t("Salida")} {salida}</div>
                        </div>

                        <div className="mb-3 row">
                            <div className="col-12">{adultos + " " + t("Adultos")} {ninos > 0 ? " , " + ninos + " " + t("Niños") : ""} {bebes > 0 ? " , " + bebes + " " + t("Bebés") : ""}</div>
                        </div>

                        {/* ***** */}

                        <div className="mb-3 row">
                            <div className="col-8">{cuentaNoches + " " + t("Noches")}</div>
                            <div className="col-4 text-end pricebase">{precioBase + " " + moneda} </div>
                        </div>

                        <div className="mt-2 mb-2 linia"></div>

                        <div className="mb-3 row">
                            <div className="col-12">{t("Cargos y tasas de servicio")}</div>
                        </div>

                        <div className="mb-3 row">
                            <div className="col-8">{t("Extras")}</div>
                            <div className="col-4 text-end"><span id="pextra" ref={pextraRef}>{(propiedad.fees / 100).toFixed(2)}</span> {moneda}</div>
                        </div>

                        <div className="mt-2 mb-2 linia"></div>

                        <div className="mb-3 row">
                            <div className="col-8 fw-bolder">{t("Total")}</div>
                            <div className="col-4 text-end fw-bolder totalprice">{(parseFloat(precioBase) + parseFloat(propiedad.fees / 100)).toFixed(2)} {moneda} </div>
                        </div>

                        {/* ***** */}

                        <div className="mt-2 mb-2 linia"></div>

                        <div className="mt-3 mb-5 text-center row">
                            <button className="btn btn-primary" onClick={handleSubmit} style={{ background: "" + websgrupo.color_corporativo_web + "", border: "1px solid " + websgrupo.color_corporativo_web }}>
                                {t("Petición")}
                            </button>
                        </div>

                        <p className="mt-5 mb-0">
                            <input type="checkbox" name="aceptacondiciones" required defaultChecked id="aceptacondiciones" ref={aceptacondicionesRef} />
                            <span className="small ms-2 text-start">
                                {t("Al reservar, acepta los")}<a href="./terminos" target="_blank"> {t("términos y condiciones")}</a>, {t("así como la")} <a href="/politica-de-cancelacion" target="_blank">{t("política de cancelación")}</a>. *
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            {/* *********** */}
            <span id="datosreservaactuales" ref={datosreservaactualesRef} data-precioestancia={precioBase} data-totalprice={parseFloat(precioBase) + parseFloat(propiedad.fees / 100)} data-prepago={parseFloat(precioBase) + parseFloat(propiedad.fees / 100)} data-resto="0.00" data-extras={propiedad.fees} data-tpcprepago="100" data-fees="0" data-precioextratarifa="0"></span>
            {/* *********** */}
        </div>
    );
}

export default DatosPeticion;
